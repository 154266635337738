// Unique DOM Id function
var counter = 1
export const uniqueDomId = function(prefix = '') {
  return prefix + counter++
}

// Doc ready function
export const docReady = function (fn) {
  if (document.readyState === 'loading') { // see if DOM is already available
    document.addEventListener('DOMContentLoaded', fn)
  } else {
    setTimeout(fn, 1)
  }
}

// Display a loader inside a content (used .processing and .load)
export const loading = function (el, display = true) {
  //const content = el.querySelector('.load')
  const loader = el.querySelector('.processing')
  if (loader) {
    let id = ''
    if (el.hasAttribute('data-loading'))
      id = el.getAttribute('data-loading')
    else {
      id = uniqueDomId()
      el.setAttribute('data-loading', id)
    }
    if (display) {
      show(loader, id)
      //hide(content)
    } else {
      hide(loader, id)
      //show(content)
    }
  }
}

// Show an element
var showing = []
export const show = function (elem, id) {
  elem.classList.remove('visually-hidden')
  elem.classList.add('is-visible')
  clearTimeout(showing[id])
  showing[id] = window.setTimeout(function () {
    //console.log(elem, ' shown')
  }, 500)
}

// Hide an element
//var hiding = []
export const hide = function (elem, id) {
  elem.classList.remove('is-visible')
  clearTimeout(showing[id])
  showing[id] = window.setTimeout(function () {
    elem.classList.add('visually-hidden')
  }, 500)
}

// Extend function json objects to merge as arguments (for a deep extend, set the first argument to `true`)
export const extend = function () {
  // Variables
  const extended = {}
  let deep = false
  let i = 0
  const length = arguments.length
  // Check if a deep merge
  if (Object.prototype.toString.call(arguments[0]) === '[object Boolean]') {
    deep = arguments[0]
    i++
  }
  // Merge the object into the extended object
  const merge = function (obj) {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        // If deep merge and property is an object, merge properties
        if (deep && Object.prototype.toString.call(obj[prop]) === '[object Object]') {
          extended[prop] = extend(true, extended[prop], obj[prop])
        } else {
          extended[prop] = obj[prop]
        }
      }
    }
  }
  // Loop through each object and conduct a merge
  for (; i < length; i++) {
    const obj = arguments[i]
    merge(obj)
  }
  return extended
}

// Check if an object is empty
export const isObjectEmpty = (obj) => {
  /*
  return (
    obj &&
    Object.keys(obj).length === 0 &&
    obj.constructor === Object
  )*/
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false
    }
  }
  return true
}

// Wait while GMap API is loading
export function dtectMap(action) {
  if (document.body.classList.contains('maploaded')) {
    action()
  } else {
    setTimeout(function() {
      dtectMap(action)
    }, 200)
  }
}
