/* fichier JS de base commun quelques soient les versions du projet web */
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/dropdown'
import 'lazysizes/plugins/aspectratio/ls.aspectratio' // lazy loading

// Styles
// import './scss/bootstrap' // bootstrap => customisez bootstrap directement
// import './scss/custom' // global styles

// Scripts librairies
import { docReady, loading } from './js/libs/utils'
import { handleNav, sticky } from './js/libs/misc'

document.body.classList.remove('no-js')

// lazysizes : simple support to lazyload js / css / bg img
document.addEventListener('lazybeforeunveil', e => {
  // bg imgs
  const bg = e.target.getAttribute('data-background')
  if (bg) e.target.style.backgroundImage = 'url(' + bg + ')'
  // css elements
  const cssEl = e.target.getAttribute('data-css-element')
  if (cssEl) loadCss(cssEl)
  // js elements
  const jsEl = e.target.getAttribute('data-js-element')
  if (jsEl) loadJs(jsEl, e.target)
})

// Css elements
function loadCss (el) {
  import(/* webpackChunkName: "[request]" */ `./scss/components/${el}.scss`)
}

// Js elements
function loadJs (el, target) {
  loading(target)
  import(/* webpackChunkName: "[request]" */ `./js/components/${el}.js`).then(() => {
    loading(target, false)
  })
}

// Scroll to top
function getScrollToTop () {
  import(/* webpackChunkName: "scrollToTop" */ './js/components/scrollToTop.js')
}

// Actions principales communes à toutes les pages
docReady(function () {
  document.addEventListener('scroll', () => {
    if (document.querySelector('html').scrollTop >= 200 && !document.getElementById('scrollUp')) { getScrollToTop() }
    sticky()
  })

  // boutons liens
  document.querySelectorAll('button[data-toggle="link"]').forEach(btn => {
    btn.addEventListener('click', e => {
      const target = e.currentTarget.getAttribute('data-target')
      const href = e.currentTarget.getAttribute('data-href')
      if (target && target === 'self') { document.location.href = href } else { window.open(href) }
    })
  })

  // anchors
  document.querySelectorAll('.scroll').forEach(anchor => {
    anchor.addEventListener('click', e => {
      e.preventDefault()
      const target = document.getElementById(this.getAttribute('target'))
      if (target) { target.scrollIntoView({ behavior: 'smooth' }) }
    })
  })

  handleNav()
  sticky()
})
