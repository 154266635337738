// Scripts librairies
// import { docReady } from './js/libs/utils'

// Styles
import './scss/bootstrap' // bootstrap => customisez bootstrap directement
import './scss/custom' // global styles

// Charge la base commune
require('./common.js')

// docReady(function () {
//  console.log('Homepage template')
// })
